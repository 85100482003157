@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
}

.focus-visible {
  outline-color: #4CBB17;
}

/* App Fonts - only include what is necessary */
@font-face {
  font-family: 'Mulish';
  src: url(./fonts/Mulish-Regular.woff2) format('woff2'),
    url(./fonts/Mulish-Regular.woff) format('woff'),
    url(./fonts/Mulish-Regular.ttf) format('ttf'),
    url(./fonts/Mulish-Regular.otf) format('otf');
  font-display: swap;
}